import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Header, Modal, Table } from '../../components';
import { Search } from '../../components/Header/components';
import { Block, Button, CheckBox, FileInput, SelectInput, Text, TextArea, TextInput } from '../../components/layout';
import ButtonAction from '../../components/Table/components/ButtonAction';
import { SvgIconCirclePlus, SvgIconDown, SvgIconEdit, SvgIconModalDelete, SvgIconTrash, SvgIconUp } from '../../svgs';
import { store } from '../../utils/store';
import validateFormSchema from '../../utils/validateFormSchema';
import SubRow from './components/SubRow';
import TicketsInput from './components/TicketsInput';

function Events() {
    const history = useHistory();
    const { language } = useContext(store);
    const inputRef = useRef(null);
    const paginate = useRef(false);
    const [search, setSearch] = useState("");

    const [isModalFiltersOpen, setIsModalFiltersOpen] = useState(false);
    const [filterFeatured, setFilterFeatured] = useState(undefined);
    const [filterRecommended, setFilterRecommended] = useState(undefined);
    const [filterPlace, setFilterPlace] = useState(undefined);
    const [filterAreas, setFilterAreas] = useState(undefined);
    const [filterStatus, setFilterStatus] = useState(undefined);
    const [filterPromoter, setFilterPromoter] = useState(undefined);
    const [filterDateStart, setFilterDateStart] = useState(undefined);
    const [filterDateEnd, setFilterDateEnd] = useState(undefined);

    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [modalCreateExcellIsOpen, setModalCreateExcellIsOpen] = useState(false);
    const [modalUpdateData, setModalUpdateData] = useState(null);
    const [modalDeleteData, setModalDeleteData] = useState(null);

    const [modalCreatePlaceData, setModalCreatePlaceData] = useState(null);
    const [modalUpdatePlaceData, setModalUpdatePlaceData] = useState(null);
    const [modalDeletePlaceData, setModalDeletePlaceData] = useState(null);
    const [rowsOpened, setRowsOpened] = useState([]);

    const [ticketOffices, setTicketOffices] = useState([]);
    const [areas, setAreas] = useState([]);
    const [places, setPlaces] = useState([]);
    const [promoters, setPromoters] = useState([]);
    const [result, setResult] = useState({ edges: [], pageInfo: { hasNextPage: false }, totalCount: 0 });

    const [queryAreas, { loading: loadingAreas }] = useLazyQuery(
        gql`
            query ($search: String) {
                areas(first: 3, search: $search) {
                    edges {
                        node {
                            id
                            name
                        }
                        cursor
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.areas) {
                    setAreas(data.areas.edges.map((edge) => ({ value: edge.node.id, label: edge.node.name })));
                }
            }
        }
    );

    const [queryTicketOffices, { loading: loadingTicketOffices }] = useLazyQuery(
        gql`
            query ($search: String) {
                ticketOffices(first: 1000, search: $search) {
                    edges {
                        node {
                            id: name
                            name
                        }
                        cursor
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.ticketOffices) {
                    setTicketOffices(data.ticketOffices.edges.map((edge) => ({ value: edge.node.id, label: edge.node.name })));
                }
            }
        }
    );

    const [queryPlaces, { loading: loadingPlaces }] = useLazyQuery(
        gql`
            query ($search: String) {
                places(first: 3, search: $search) {
                    edges {
                        node {
                            id
                            name
                        }
                        cursor
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.places) {
                    setPlaces(data.places.edges.map((edge) => ({ value: edge.node.id, label: edge.node.name })));
                }
            }
        }
    );

    const [queryPromoters, { loading: loadingPromoters }] = useLazyQuery(
        gql`
            query ($search: String) {
                promoters(first: 3, search: $search) {
                    edges {
                        node {
                            id
                            company
                        }
                        cursor
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.promoters) {
                    setPromoters(data.promoters.edges.map((edge) => ({ value: edge.node.id, label: edge.node.company })));
                }
            }
        }
    );

    const [queryEvents, { loading }] = useLazyQuery(
        gql`
            query ($first: Int, $after: String, $search: String, $featured: Boolean, $recommended: Boolean, $place: ID, $areas: [ID!], $status: EventStatus, $promoter: ID, $dateStart: String, $dateEnd: String) {
                events(first: $first, after: $after, search: $search, featured: $featured, recommended: $recommended, place: $place, areas: $areas, status: $status, promoter: $promoter, dateStart: $dateStart, dateEnd: $dateEnd) {
                    edges {
                        node {
                            id
                            name
                            thumbnail
                            thumbnailWeb
                            duration
                            minTickerPrice
                            maxTickerPrice
                            minAge
                            aboutTitle
                            aboutDescription
                            status
                            areas {
                                id
                                name
                            }
                            places {
                                id
                                event {
                                    id
                                }
                                place {
                                    id
                                    name
                                }
                                isVirtual
                                isInPerson
                                ticketsUrls
                                date
                                time
                            }
                            featured
                            recommended
                            critic
                            site
                            promoter {
                                id
                                company
                            }
                        }
                        cursor
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                    totalCount
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.events) {
                    setResult({
                        edges: paginate.current ? [...result.edges, ...data.events.edges] : data.events.edges,
                        pageInfo: data.events.pageInfo,
                        totalCount: data.events.totalCount
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [mutationEventCreate, { loading: loadingEventCreate }] = useMutation(
        gql`
            mutation eventCreate($name: String!, $thumbnail: Json, $thumbnailWeb: Json, $duration: String, $minTickerPrice: String, $maxTickerPrice: String, $minAge: String, $aboutTitle: String, $aboutDescription: String, $status: EventStatus, $areas: [ID!], $featured: Boolean, $recommended: Boolean, $critic: String, $site: String, $promoter: ID) {
                eventCreate(name: $name, thumbnail: $thumbnail, thumbnailWeb: $thumbnailWeb, duration: $duration, minTickerPrice: $minTickerPrice, maxTickerPrice: $maxTickerPrice, minAge: $minAge, aboutTitle: $aboutTitle, aboutDescription: $aboutDescription, status: $status, areas: $areas, featured: $featured, recommended: $recommended, critic: $critic, site: $site, promoter: $promoter) {
                    id
                    name
                    thumbnail
                    thumbnailWeb
                    duration
                    minTickerPrice
                    maxTickerPrice
                    minAge
                    aboutTitle
                    aboutDescription
                    status
                    areas {
                        id
                        name
                    }
                    places {
                        id
                        event {
                            id
                        }
                        place {
                            id
                            name
                        }
                        isVirtual
                        isInPerson
                        ticketsUrls
                        date
                        time
                    }
                    featured
                    recommended
                    critic
                    site
                    promoter {
                        id
                        company
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                requestData();
                setModalCreateIsOpen(false);
            }
        }
    );

    const [mutationEventUpdate, { loading: loadingEventUpdate }] = useMutation(
        gql`
                mutation eventUpdate($id: ID!, $name: String, $thumbnail: Json, $thumbnailWeb: Json, $duration: String, $minTickerPrice: String, $maxTickerPrice: String, $minAge: String, $aboutTitle: String, $aboutDescription: String, $status: EventStatus, $areas: [ID!], $featured: Boolean, $recommended: Boolean, $critic: String, $site: String, $promoter: ID) {
                    eventUpdate(id: $id, name: $name, thumbnail: $thumbnail, thumbnailWeb: $thumbnailWeb, duration: $duration, minTickerPrice: $minTickerPrice, maxTickerPrice: $maxTickerPrice, minAge: $minAge, aboutTitle: $aboutTitle, aboutDescription: $aboutDescription, status: $status, areas: $areas, featured: $featured, recommended: $recommended, critic: $critic, site: $site, promoter: $promoter) {
                        id
                        name
                        thumbnail
                        thumbnailWeb
                        duration
                        minTickerPrice
                        maxTickerPrice
                        minAge
                        aboutTitle
                        aboutDescription
                        status
                        areas {
                            id
                            name
                        }
                        places {
                            id
                            event {
                                id
                            }
                            place {
                                id
                                name
                            }
                            isVirtual
                            isInPerson
                            ticketsUrls
                            date
                            time
                        }
                        featured
                        recommended
                        critic
                        site
                        promoter {
                            id
                            company
                        }
                    }
                }
            `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.eventUpdate) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.eventUpdate.id);
                    if (index !== -1) {
                        result.edges[index].node = { ...data.eventUpdate };
                        setModalUpdateData(null);
                        setResult([...result]);
                    }
                }
            }
        }
    );

    const [mutationEventDelete, { loading: loadingEventDelete }] = useMutation(
        gql`
            mutation eventDelete($id: ID!) {
                eventDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.eventDelete) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.eventDelete.id);
                    if (index !== -1) {
                        result.edges.splice(index, 1);
                        setModalDeleteData(null);
                        setResult([...data]);
                    }
                }
            }
        }
    );

    const [mutationEventCreatePlace, { loading: loadingEventCreatePlace }] = useMutation(
        gql`
            mutation eventCreatePlace($eventId: ID!, $placeId: ID!, $isVirtual: Boolean, $isInPerson: Boolean, $ticketsUrls: Json, $date: String!, $dateUntil: String, $time: String) {
                eventCreatePlace(eventId: $eventId, placeId: $placeId, isVirtual: $isVirtual, isInPerson: $isInPerson, ticketsUrls: $ticketsUrls, date: $date, dateUntil: $dateUntil, time: $time) {
                    id
                    event {
                        id
                    }
                    place {
                        id
                        name
                    }
                    isVirtual
                    isInPerson
                    ticketsUrls
                    date
                    time
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.eventCreatePlace) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.eventCreatePlace[0].event.id);
                    if (index !== -1) {
                        result.edges[index].node.places.push(...data.eventCreatePlace);
                        setModalCreatePlaceData(null);
                        setResult([...result]);
                    }
                }
            }
        }
    );

    const [mutationEventUpdatePlace, { loading: loadingEventUpdatePlace }] = useMutation(
        gql`
            mutation eventUpdatePlace($id: ID!, $placeId: ID, $isVirtual: Boolean, $isInPerson: Boolean, $ticketsUrls: Json, $date: String!, $time: String) {
                eventUpdatePlace(id: $id, placeId: $placeId, isVirtual: $isVirtual, isInPerson: $isInPerson, ticketsUrls: $ticketsUrls, date: $date, time: $time) {
                    id
                    event {
                        id
                    }
                    place {
                        id
                        name
                    }
                    isVirtual
                    isInPerson
                    ticketsUrls
                    date
                    time
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.eventUpdatePlace) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.eventUpdatePlace.event.id);
                    if (index !== -1) {
                        const indexPlaces = result.edges[index].node.places.findIndex((place) => place.id == data.eventUpdatePlace.id);
                        if (indexPlaces !== -1) {
                            result.edges[index].node.places[indexPlaces] = data.eventUpdatePlace;
                            setModalUpdatePlaceData(null);
                            setResult([...result]);
                        }
                    }
                }
            }
        }
    );

    const [mutationEventDeletePlace, { loading: loadingEventDeletePlace }] = useMutation(
        gql`
            mutation eventDeletePlace($id: ID!) {
                eventDeletePlace(id: $id) {
                    id
                    event {
                        id
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.eventDeletePlace) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.eventDeletePlace.event.id);
                    if (index !== -1) {
                        const indexPlaces = result.edges[index].node.places.findIndex((place) => place.id == data.eventDeletePlace.id);
                        if (indexPlaces !== -1) {
                            result.edges[index].node.places.splice(indexPlaces, 1);
                            setModalDeletePlaceData(null);
                            setResult([...result]);
                        }
                    }
                }
            }
        }
    );

    const [mutationEventImportCsv, { loading: loadingEventImportCsv }] = useMutation(
        gql`
            mutation eventImportCsv($file: Upload!) {
                eventImportCsv(file: $file)
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.eventImportCsv) {
                    inputRef.current.value = null;
                    requestData();
                }
            }
        }
    );

    const [mutationEventDuplicatePlace, { loading: loadingEventDuplicatePlace }] = useMutation(
        gql`
            mutation eventDuplicatePlace($id: ID!) {
                eventDuplicatePlace(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.eventDuplicatePlace) {
                    setModalUpdateData(null);
                    requestData();
                }
            }
        }
    );

    const requestData = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        queryEvents({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined,
                featured: filterFeatured === true ? filterFeatured : undefined,
                recommended: filterRecommended === true ? filterRecommended : undefined,
                place: !!filterPlace ? filterPlace : undefined,
                areas: !!filterAreas ? filterAreas : undefined,
                status: !!filterStatus ? filterStatus : undefined,
                promoter: !!filterPromoter ? filterPromoter : undefined,
                dateStart: filterDateStart !== "" ? filterDateStart : undefined,
                dateEnd: filterDateEnd !== "" ? filterDateEnd : undefined,
            }
        });
    }

    useEffect(() => {
        queryAreas();
        queryTicketOffices();
        queryPlaces();
        queryPromoters();
        requestData();
    }, [language]);

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            requestData();
        }, 350);

        return () => {
            clearTimeout(debounceTimeout);
        };
    }, [
        search,
        filterFeatured,
        filterRecommended,
        filterPlace,
        JSON.stringify(filterAreas),
        filterStatus,
        filterPromoter,
        filterDateStart,
        filterDateEnd
    ]);

    return (
        <>
            <input ref={inputRef} type="file" style={{ display: "none" }} onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                    mutationEventImportCsv({ variables: { file: e.target.files[0] } });
                }
            }} />
            <Block flex height="100%">
                <Header
                    leftChildren={<Search onChange={(e) => setSearch(e.target.value)} />}
                    rightChildren={
                        <Block row>
                            <Button style={{ minWidth: 140, marginRight: "1px" }} text="Filters" onClick={() => setIsModalFiltersOpen(true)} />
                            <Button style={{ minWidth: 140, marginRight: "1px" }} text="Load CSV" onClick={() => inputRef.current.click()} loading={loadingEventImportCsv} />
                            <Button style={{ minWidth: 309, marginRight: "1px" }} text="Add Event" onClick={() => setModalCreateIsOpen(true)} />
                            {/* <Button style={{ minWidth: 309 }} text="Add Event Excell" onClick={() => history.push("/dashboard/events/create")} /> */}
                        </Block>
                    }
                />
                <Block height="100%" mr={20} ml={20} style={{ overflow: "hidden" }}>
                    <Table
                        loading={loading}
                        columns={[
                            { label: "Name", key: "name" },
                            { label: "Status", key: "status" },
                            { label: "Featured", key: "featured" },
                            { label: "Recommended", key: "recommended" },
                            { label: "Promoter", key: "promoter" }
                        ]}
                        rows={result.edges.map((edge) => {
                            const rowOpenedIndex = rowsOpened.findIndex((rowOpened) => rowOpened == edge.cursor);

                            return {
                                name: edge.node.name,
                                status: edge.node.status,
                                featured: edge.node.featured === true ? 'Yes' : 'No',
                                recommended: edge.node.recommended === true ? 'Yes' : 'No',
                                promoter: !!edge.node.promoter ? edge.node.promoter.company : "-",
                                subRowsOpen: !!rowsOpened.find((rowOpened) => rowOpened == edge.cursor),
                                subRows: edge.node.places && edge.node.places.map((place) => {
                                    return (
                                        <SubRow
                                            key={place.id}
                                            title={place.place.name}
                                            date={place.date}
                                            time={place.time}
                                            actions={<>
                                                <ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => setModalUpdatePlaceData({ ...place, placeId: place.place.id })} />
                                                <ButtonAction icon={<SvgIconTrash />} onClick={() => setModalDeletePlaceData(place.id)} />
                                            </>}
                                        />
                                    );
                                }),
                                actions: <>
                                    <ButtonAction icon={<SvgIconCirclePlus />} text="Add Place" onClick={() => setModalCreatePlaceData(edge.cursor)} />
                                    {(edge.node.places && edge.node.places.length > 0) && <ButtonAction icon={rowOpenedIndex === -1 ? <SvgIconUp /> : <SvgIconDown />} text="Places" onClick={() => {
                                        if (rowOpenedIndex === -1) {
                                            rowsOpened.push(edge.cursor);
                                        } else {
                                            rowsOpened.splice(rowOpenedIndex, 1);
                                        }
                                        setRowsOpened([...rowsOpened]);
                                    }} />}
                                    <ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => setModalUpdateData({ ...edge.node, areas: edge.node.areas.map((area) => area.id), promoter: edge.node.promoter && edge.node.promoter.id })} />
                                    <ButtonAction icon={<SvgIconTrash />} onClick={() => setModalDeleteData(edge.cursor)} />
                                </>
                            }
                        })}
                        next={() => requestData({ next: true, after: result.pageInfo.endCursor })}
                        hasMore={result.pageInfo.hasNextPage}
                    />
                </Block>
            </Block>
            <Modal
                title="Create Event"
                isOpen={modalCreateIsOpen}
                onRequestClose={() => setModalCreateIsOpen(false)}
            >
                <Text size={14} height={20}>Event</Text>
                <Block mt={28}>
                    <Formik
                        initialValues={{}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required(),
                                    thumbnail: yup.mixed(),
                                    thumbnailWeb: yup.mixed(),
                                    duration: yup.string().nullable(),
                                    minTickerPrice: yup.number().nullable(),
                                    maxTickerPrice: yup.number().nullable(),
                                    minAge: yup.string().nullable(),
                                    aboutTitle: yup.string().nullable(),
                                    aboutDescription: yup.string().nullable(),
                                    status: yup.string().required(),
                                    areas: yup.array(yup.number()),
                                    featured: yup.boolean(),
                                    recommended: yup.boolean(),
                                    critic: yup.string().nullable(),
                                    site: yup.string().url().nullable(),
                                    promoter: yup.number().nullable()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationEventCreate({ variables: { ...values } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.name} />
                                            </Block>
                                            <Block mt={12}>
                                                <FileInput name="thumbnail" label="App image" onChange={handleChange} onBlur={handleBlur} error={errors.thumbnail} />
                                            </Block>
                                            <Block mt={12}>
                                                <FileInput name="thumbnailWeb" label="Web image" onChange={handleChange} onBlur={handleBlur} error={errors.thumbnailWeb} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="duration" label="Duration" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.duration} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="minTickerPrice" label="Min ticket price" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.minTickerPrice} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="maxTickerPrice" label="Max ticket price" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.maxTickerPrice} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="minAge" label="Min age" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.minAge} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="aboutTitle" label="About title" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.aboutTitle} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextArea name="aboutDescription" label="About description" onChange={handleChange} onBlur={handleBlur} error={errors.aboutDescription} />
                                            </Block>
                                            <Block mt={12}>
                                                <SelectInput name="areas" label="Areas" onChange={handleChange} onBlur={handleBlur} isMulti error={errors.areas} options={areas} onChangeSearch={(search) => queryAreas({ variables: { search } })} />
                                            </Block>
                                            <Block mt={12}>
                                                <SelectInput name="status" label="Status" onChange={handleChange} onBlur={handleBlur} error={errors.status} options={[{ label: "Published", value: "PUBLISHED" }, { label: "Draft", value: "DRAFT" }]} />
                                            </Block>
                                            <Block mt={12}>
                                                <CheckBox name="featured" label="Featured" onChange={handleChange} onBlur={handleBlur} error={errors.featured} />
                                            </Block>
                                            <Block mt={12}>
                                                <CheckBox name="recommended" label="Recommended" onChange={handleChange} onBlur={handleBlur} error={errors.recommended} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextArea name="critic" label="Critic" onChange={handleChange} onBlur={handleBlur} error={errors.critic} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="site" label="Site" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.site} />
                                            </Block>
                                            <Block mt={12}>
                                                <SelectInput name="promoter" label="Promoter" onChange={handleChange} onBlur={handleBlur} error={errors.promoter} options={promoters} onChangeSearch={(search) => queryPromoters({ variables: { search } })} />
                                            </Block>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingEventCreate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                title="Update Event"
                isOpen={!!modalUpdateData}
                onRequestClose={() => setModalUpdateData(null)}
            >
                <Text size={14} height={20}>Event</Text>
                <Block mt={28}>
                    <Formik
                        enableReinitialize
                        initialValues={modalUpdateData}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string(),
                                    thumbnail: yup.mixed(),
                                    thumbnailWeb: yup.mixed(),
                                    duration: yup.string().nullable(),
                                    minTickerPrice: yup.number().nullable(),
                                    maxTickerPrice: yup.number().nullable(),
                                    minAge: yup.string().nullable(),
                                    aboutTitle: yup.string().nullable(),
                                    aboutDescription: yup.string().nullable(),
                                    status: yup.string().required(),
                                    areas: yup.array(yup.number()),
                                    featured: yup.boolean(),
                                    recommended: yup.boolean(),
                                    critic: yup.string().nullable(),
                                    site: yup.string().url().nullable(),
                                    promoter: yup.number().nullable()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationEventUpdate({ variables: { id: modalUpdateData.id, ...values } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} error={errors.name} />
                                            </Block>
                                            <Block mt={12}>
                                                <FileInput name="thumbnail" label="App image" onChange={handleChange} onBlur={handleBlur} value={values.thumbnail} error={errors.thumbnail} />
                                            </Block>
                                            <Block mt={12}>
                                                <FileInput name="thumbnailWeb" label="Web image" onChange={handleChange} onBlur={handleBlur} value={values.thumbnailWeb} error={errors.thumbnailWeb} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="duration" label="Duration" type="text" onChange={handleChange} onBlur={handleBlur} value={values.duration} error={errors.duration} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="minTickerPrice" label="Min ticket price" type="text" onChange={handleChange} onBlur={handleBlur} value={values.minTickerPrice} error={errors.minTickerPrice} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="maxTickerPrice" label="Max ticket price" type="text" onChange={handleChange} onBlur={handleBlur} value={values.maxTickerPrice} error={errors.maxTickerPrice} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="minAge" label="Min age" type="text" onChange={handleChange} onBlur={handleBlur} value={values.minAge} error={errors.minAge} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="aboutTitle" label="About title" type="text" onChange={handleChange} onBlur={handleBlur} value={values.aboutTitle} error={errors.aboutTitle} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextArea name="aboutDescription" label="About description" onChange={handleChange} onBlur={handleBlur} value={values.aboutDescription} error={errors.aboutDescription} />
                                            </Block>
                                            <Block mt={12}>
                                                <SelectInput name="areas" label="Areas" onChange={handleChange} onBlur={handleBlur} isMulti error={errors.areas} options={areas} onChangeSearch={(search) => queryAreas({ variables: { search } })} />
                                            </Block>
                                            <Block mt={12}>
                                                <SelectInput name="status" label="Status" type="text" onChange={handleChange} onBlur={handleBlur} value={values.status} error={errors.status} options={[{ label: "Published", value: "PUBLISHED" }, { label: "Draft", value: "DRAFT" }]} />
                                            </Block>
                                            <Block mt={12}>
                                                <CheckBox name="featured" label="Featured" onChange={handleChange} onBlur={handleBlur} value={values.featured} error={errors.featured} />
                                            </Block>
                                            <Block mt={12}>
                                                <CheckBox name="recommended" label="Recommended" onChange={handleChange} onBlur={handleBlur} value={values.recommended} error={errors.recommended} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextArea name="critic" label="Critic" onChange={handleChange} onBlur={handleBlur} value={values.critic} error={errors.critic} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="site" label="Site" type="text" onChange={handleChange} onBlur={handleBlur} value={values.site} error={errors.site} />
                                            </Block>
                                            <Block mt={12}>
                                                <SelectInput name="promoter" label="Promoter" onChange={handleChange} onBlur={handleBlur} error={errors.promoter} options={promoters} onChangeSearch={(search) => queryPromoters({ variables: { search } })} />
                                            </Block>
                                        </Block>
                                        <Block mt={44} space="between" row>
                                            <Button type="submit" text="Submit" loading={loadingEventUpdate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                width={280}
                title="Delete Event"
                isOpen={!!modalDeleteData}
                onRequestClose={() => setModalDeleteData(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this event?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this event.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingEventDelete} onClick={() => mutationEventDelete({ variables: { id: modalDeleteData } })} />
                </Block>
            </Modal>

            <Modal
                title="Create Place"
                isOpen={!!modalCreatePlaceData}
                onRequestClose={() => setModalCreatePlaceData(null)}
            >
                <Text size={14} height={20}>Place</Text>
                <Block mt={28}>
                    <Formik
                        initialValues={{}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    placeId: yup.number().required(),
                                    isVirtual: yup.boolean().nullable(),
                                    isInPerson: yup.boolean().nullable(),
                                    ticketsUrls: yup.array(yup.object().shape({ name: yup.string().required(), url: yup.string().url().required() })),
                                    date: yup.date().required(),
                                    date: yup.date(),
                                    time: yup.string().nullable()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationEventCreatePlace({ variables: { eventId: modalCreatePlaceData, ...values, time: values.time === "" ? null : values.time } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <SelectInput name="placeId" label="Place" onChange={handleChange} onBlur={handleBlur} error={errors.placeId} options={places} onChangeSearch={(search) => queryPlaces({ variables: { search } })} />
                                            </Block>
                                            <Block mt={12}>
                                                <CheckBox name="isVirtual" label="Is virtual" onChange={handleChange} onBlur={handleBlur} error={errors.isVirtual} />
                                            </Block>
                                            <Block mt={12}>
                                                <CheckBox name="isInPerson" label="Is in person" onChange={handleChange} onBlur={handleBlur} error={errors.isInPerson} />
                                            </Block>
                                            <Block mt={12}>
                                                <TicketsInput name="ticketsUrls" label="Tickets URL" onChange={handleChange} onBlur={handleBlur} value={values.ticketsUrls} errors={errors} ticketOffices={ticketOffices} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="date" label="Date" type="date" onChange={handleChange} onBlur={handleBlur} error={errors.date} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="dateUntil" label="Date Until" type="date" onChange={handleChange} onBlur={handleBlur} error={errors.dateUntil} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="time" label="Time" type="time" onChange={handleChange} onBlur={handleBlur} error={errors.time} />
                                            </Block>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingEventCreatePlace} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                title="Update Place"
                isOpen={!!modalUpdatePlaceData}
                onRequestClose={() => setModalUpdatePlaceData(null)}
            >
                <Text size={14} height={20}>Place</Text>
                <Block mt={28}>
                    <Formik
                        enableReinitialize
                        initialValues={modalUpdatePlaceData}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    placeId: yup.number().required(),
                                    isVirtual: yup.boolean().nullable(),
                                    isInPerson: yup.boolean().nullable(),
                                    ticketsUrls: yup.array(yup.object().shape({ name: yup.string().required(), url: yup.string().url().required() })),
                                    date: yup.date().required(),
                                    time: yup.string().nullable()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationEventUpdatePlace({ variables: { id: modalUpdatePlaceData.id, ...values, time: values.time === "" ? null : values.time } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <SelectInput name="placeId" label="Place" onChange={handleChange} onBlur={handleBlur} error={errors.placeId} options={places} onChangeSearch={(search) => queryPlaces({ variables: { search } })} />
                                            </Block>
                                            <Block mt={12}>
                                                <CheckBox name="isVirtual" label="Is virtual" onChange={handleChange} onBlur={handleBlur} value={values.isVirtual} error={errors.isVirtual} />
                                            </Block>
                                            <Block mt={12}>
                                                <CheckBox name="isInPerson" label="Is in person" onChange={handleChange} onBlur={handleBlur} value={values.isInPerson} error={errors.isInPerson} />
                                            </Block>
                                            <Block mt={12}>
                                                <TicketsInput name="ticketsUrls" label="Tickets URL" onChange={handleChange} onBlur={handleBlur} value={values.ticketsUrls} errors={errors} ticketOffices={ticketOffices} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="date" label="Date" type="date" onChange={handleChange} onBlur={handleBlur} value={values.date} error={errors.date} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="time" label="Time" type="time" onChange={handleChange} onBlur={handleBlur} value={values.time} error={errors.time} />
                                            </Block>
                                        </Block>
                                        <Block mt={44} space="between" row>
                                            <Button type="submit" text="Submit" loading={loadingEventUpdatePlace} />
                                            <Button type="button" text="Duplicate" loading={loadingEventDuplicatePlace} onClick={() => mutationEventDuplicatePlace({ variables: { id: modalUpdatePlaceData.id } })} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                width={280}
                title="Delete Place"
                isOpen={!!modalDeletePlaceData}
                onRequestClose={() => setModalDeletePlaceData(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this place?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this place.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingEventDeletePlace} onClick={() => mutationEventDeletePlace({ variables: { id: modalDeletePlaceData } })} />
                </Block>
            </Modal>

            <Modal
                title="Filters"
                isOpen={isModalFiltersOpen}
                onRequestClose={() => setIsModalFiltersOpen(false)}
            >
                <Block>
                    <Formik
                        initialValues={{
                            filterFeatured,
                            filterRecommended,
                            filterPlace,
                            filterAreas,
                            filterStatus,
                            filterPromoter,
                            filterDateStart,
                            filterDateEnd
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            setFilterFeatured(values.filterFeatured);
                            setFilterRecommended(values.filterRecommended);
                            setFilterPlace(values.filterPlace);
                            setFilterAreas(values.filterAreas);
                            setFilterStatus(values.filterStatus);
                            setFilterPromoter(values.filterPromoter);
                            setFilterDateStart(values.filterDateStart);
                            setFilterDateEnd(values.filterDateEnd);
                            setIsModalFiltersOpen(false);
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            console.log(values)
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block>
                                        <Block>
                                            <Block mt={12}>
                                                <CheckBox name="filterFeatured" label="Featured" onChange={handleChange} onBlur={handleBlur} error={errors.filterFeatured} />
                                            </Block>
                                            <Block mt={12}>
                                                <CheckBox name="filterRecommended" label="Recommended" onChange={handleChange} onBlur={handleBlur} error={errors.filterRecommended} />
                                            </Block>
                                            <Block mt={12}>
                                                <SelectInput name="filterPlace" label="Place" onChange={handleChange} onBlur={handleBlur} error={errors.filterPlace} options={places} onChangeSearch={(search) => queryPlaces({ variables: { search } })} />
                                            </Block>
                                            <Block mt={12}>
                                                <SelectInput name="filterAreas" label="Areas" onChange={handleChange} onBlur={handleBlur} isMulti error={errors.filterAreas} options={areas} onChangeSearch={(search) => queryAreas({ variables: { search } })} />
                                            </Block>
                                            <Block mt={12}>
                                                <SelectInput name="filterStatus" label="Status" onChange={handleChange} onBlur={handleBlur} value={values.filterStatus} error={errors.filterStatus} options={[{ label: "Published", value: "PUBLISHED" }, { label: "Draft", value: "DRAFT" }]} />
                                            </Block>
                                            <Block mt={12}>
                                                <SelectInput name="filterPromoter" label="Promoter" onChange={handleChange} onBlur={handleBlur} error={errors.filterPromoter} options={promoters} onChangeSearch={(search) => queryPromoters({ variables: { search } })} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="filterDateStart" label="Date Start" value={values.filterDateStart} onChange={handleChange} onBlur={handleBlur} error={errors.filterDateStart} type="date" />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="filterDateEnd" label="Date End" value={values.filterDateEnd} onChange={handleChange} onBlur={handleBlur} error={errors.filterDateEnd} type="date" />
                                            </Block>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingEventCreatePlace} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
        </>
    );
}

export default Events;